import { Dispatch } from 'react';
import { destroyCookie, setCookie } from 'nookies';

import { notify } from 'Components/common/notify';

import { receiveUserDetails } from 'Actions/user';

import { COOKIE, TIME } from 'Constants/constants';

import { identifyUser, sendUserVariablesToDataLayer } from './analytics';
import { error } from './logUtils';
import { getApiBaseUrl } from './urlUtils';

export const autoLoginUserWithTokenUrl = async (
	loginUrl: string,
	dispatch: Dispatch<any>,
	onFailure: () => void = () => {},
): Promise<boolean> => {
	try {
		const url = new URL(loginUrl);
		const token = url.searchParams.get('token');
		const options = {
			method: 'POST',
			body: JSON.stringify({
				token: token,
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		};
		const response = await fetch(
			`${getApiBaseUrl()}/api/v3/account/token/login`,
			options,
		);
		const userDetails = await response.json();
		if (userDetails.customerId) {
			setUserCookie(userDetails);
			dispatch(receiveUserDetails({ json: userDetails }));
			sendUserVariablesToDataLayer(userDetails);
			identifyUser({
				emailId: userDetails.email,
				attributes: userDetails,
			});
			return true;
		} else {
			throw new Error('login failed!');
		}
	} catch (err) {
		notify.showMembershipLoginError(err, 'login');
		onFailure();
		error(err);
		return false;
	}
};

const setUserCookie = (response: any) => {
	const { accessToken } = response;

	// to remove old cookies if exist's
	destroyCookie(null, COOKIE.HEADOUT_ACCESS_TOKEN);
	destroyCookie(null, COOKIE.IS_SIGNED_IN);

	setCookie(null, COOKIE.HEADOUT_ACCESS_TOKEN, accessToken, {
		domain: `.${window?.location?.hostname?.replace('www.', '')}`,
		httpOnly: false,
		maxAge: 1 * TIME.SECONDS_IN_YEARS,
		path: '/',
		secure: true,
	});
	setCookie(null, COOKIE.IS_SIGNED_IN, 'true', {
		httpOnly: false,
		maxAge: 1 * TIME.SECONDS_IN_YEARS,
		path: '/',
		secure: true,
	});
	setCookie(null, COOKIE.USER_PROFILE_TYPE, 'CUSTOMER', {
		httpOnly: false,
		maxAge: 1 * TIME.SECONDS_IN_YEARS,
		path: '/',
		secure: true,
	});
	setCookie(null, COOKIE.LOGIN_SUCCESS_TRACKER, 'true', {
		httpOnly: false,
		maxAge: 1 * TIME.SECONDS_IN_YEARS,
		path: '/',
		secure: true,
	});
};
