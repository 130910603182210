import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import styled, { keyframes } from 'styled-components';

import {
	clearMembershipDataFromAsyncStorage,
	clearUrgencyBannerUtilities,
	decodeBase64ToMembershipDetails,
	getMembershipDataFromAsyncStorage,
	IMembershipDetails,
	IMembershipQueryPayload,
	MembershipConfirmation,
	setMembershipDataInAsyncStorage,
	useSwipeSheet,
} from '@headout/aqua';

import Conditional from 'Components/common/conditional';

import { read, write } from 'Utils/localStorageUtils';
import { error } from 'Utils/logUtils';
import { getMembershipDetailsFromReduxStore } from 'Utils/stateUtils';
import {
	isCityPageUrl,
	isHomePageUrl,
	isManageBookingPageUrl,
} from 'Utils/urlUtils';
import { autoLoginUserWithTokenUrl } from 'Utils/userAutoLogin';

import { joinMembership } from 'Thunks/membership';

const PreMembershipActivationTopNudge = dynamic(
	() =>
		import('@headout/aqua').then(
			module => module.PreMembershipActivationTopNudge,
		),
	{
		ssr: false,
	},
);
const PreMembershipActivationSwipeSheet = dynamic(
	() =>
		import('@headout/aqua').then(
			module => module.PreMembershipActivationSwipeSheet,
		),
	{
		ssr: false,
	},
);
const PostActivationNudge = dynamic(
	() =>
		import('@headout/aqua').then(
			module => module.PostActivationNudgeWrapper,
		),
	{
		ssr: false,
	},
);

const fadeOut = keyframes`
  0% {
    transform: translateY(-120px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const MembershipActivationNudgeWrapper = styled.div<{
	isMarginRequired: boolean;
}>`
	overflow: hidden;
	animation: ${fadeOut} 500ms cubic-bezier(0.5, 0, 0.5, 1) 350ms both;
	margin-bottom: ${({ isMarginRequired }) =>
		isMarginRequired ? '1.5rem' : 0};
`;

interface IMembershipNudges {
	preActivation: boolean;
	postActivation: boolean;
}

export enum MembershipActivationState {
	NOT_INITIATED = 'not_initiated',
	SIGNING_IN_PROGRESS = 'signing_in_progress',
	JOIN_IN_PROGRESS = 'join_in_progress',
	JOIN_SUCCESS = 'join_success',
}

interface IReduxSelectorState {
	membershipDetailsFromReduxStore: IMembershipDetails | null;
}

export const MembershipFlowHandler = ({
	isDesktop,
}: {
	isDesktop: boolean;
}) => {
	const { query = {}, pathname = '' } = useRouter();
	const { membership_data: membershipQueryParam, membership_meta } = query;

	//getting membership data from redux store
	const { membershipDetailsFromReduxStore }: IReduxSelectorState =
		useSelector(state => {
			return {
				membershipDetailsFromReduxStore:
					getMembershipDetailsFromReduxStore(state) || null,
			};
		});
	const dispatch = useDispatch();

	const [membershipActivationState, setMembershipActivationState] =
		useState<MembershipActivationState>(
			MembershipActivationState.NOT_INITIATED,
		);
	const [resolvedMembershipPayload, setResolvedMembershipPayload] =
		useState<IMembershipQueryPayload | null>(null);
	const [showMembershipNudges, setShowMembershipNudges] =
		useState<IMembershipNudges>({
			preActivation: false,
			postActivation: false,
		});

	//setter function for showMembershipNudges
	const setMembershipNudge = (
		type: keyof IMembershipNudges,
		value: boolean,
	): void => {
		setShowMembershipNudges(prev => ({ ...prev, [type]: value }));
	};

	useEffect(() => {
		(async () => {
			/**
			 * Keeping redux store at the higher priority
			 */
			if (
				/**
				 * Either membershipDetails is not in redux store or
				 * if it is present, it is in inactive stage
				 */
				!membershipDetailsFromReduxStore ||
				(membershipDetailsFromReduxStore &&
					!membershipDetailsFromReduxStore.isActivated)
			) {
				if (membershipQueryParam) {
					clearUrgencyBannerUtilities();

					const decodedMembershipPayload =
						decodeBase64ToMembershipDetails(
							membershipQueryParam as string,
						);

					if (decodedMembershipPayload) {
						setResolvedMembershipPayload(decodedMembershipPayload);
						setMembershipDataInAsyncStorage(
							decodedMembershipPayload,
						);
					}
				} else {
					const membershipPayloadFromAsyncStorage =
						await getMembershipDataFromAsyncStorage();
					if (membershipPayloadFromAsyncStorage) {
						setResolvedMembershipPayload(
							membershipPayloadFromAsyncStorage,
						);
					}
				}
			} else if (
				membershipDetailsFromReduxStore &&
				membershipDetailsFromReduxStore?.isActivated
			) {
				setMembershipNudge('preActivation', false);
				setMembershipNudge('postActivation', true);
				clearMembershipDataFromAsyncStorage();
			}
		})();
	}, [membershipDetailsFromReduxStore, membershipQueryParam]);

	useEffect(() => {
		const { membershipDetails } = resolvedMembershipPayload || {};
		if (membershipDetails && !membershipDetails?.isActivated) {
			setMembershipNudge('preActivation', true);
		}
	}, [resolvedMembershipPayload]);

	const isPopUpShown = useRef(false);

	const {
		sheetRef: preActivationMembershipSheetRef,
		openSheet: openActivateMembershipSheet,
		closeSheet: closeActivateMembershipSheet,
	} = useSwipeSheet();

	const onMembershipConfirmationAnimationFinished = () => {
		setMembershipActivationState(MembershipActivationState.JOIN_SUCCESS);
	};

	const isHomePage = isHomePageUrl(pathname);
	const isCityPage = isCityPageUrl(pathname);
	const isManageBookingPage = isManageBookingPageUrl(pathname);
	const isMembershipNudgeShownOnPage =
		isHomePage || isCityPage || isManageBookingPage;

	if (!isMembershipNudgeShownOnPage) {
		return null;
	}

	const onJoiningMembership = async () => {
		if (membershipDetailsFromReduxStore?.isActivated) return;
		try {
			setMembershipActivationState(
				MembershipActivationState.SIGNING_IN_PROGRESS,
			);
			const loginUrl = resolvedMembershipPayload?.loginLink ?? '';
			const membershipId =
				resolvedMembershipPayload?.membershipDetails?.membershipId ?? 0;
			const loggedInSuccessfully = await autoLoginUserWithTokenUrl(
				loginUrl,
				dispatch,
				() => {
					setMembershipActivationState(
						MembershipActivationState.NOT_INITIATED,
					);
				},
			);
			if (loggedInSuccessfully) {
				// only call join if auto login is successful
				dispatch(
					joinMembership(membershipId, setMembershipActivationState),
				);
			} else {
				setMembershipActivationState(
					MembershipActivationState.NOT_INITIATED,
				);
			}
		} catch (err) {
			setMembershipActivationState(
				MembershipActivationState.NOT_INITIATED,
			);
			error(err);
		}
	};

	const isJoiningFlowAlreadyShown = read('isJoiningFlowAlreadyShown');

	if (
		resolvedMembershipPayload?.shouldShowPopup &&
		!isPopUpShown.current &&
		isJoiningFlowAlreadyShown !== 'true'
	) {
		isPopUpShown.current = true;
		onJoiningMembership();
		write('isJoiningFlowAlreadyShown', 'true');
	}

	if (membership_meta === 'open') {
		setTimeout(() => {
			openActivateMembershipSheet();
		}, 800);
	}

	if (
		membershipActivationState !== MembershipActivationState.NOT_INITIATED &&
		membershipActivationState !== MembershipActivationState.JOIN_SUCCESS
	) {
		return (
			<MembershipConfirmation
				onMembershipConfirmationAnimationFinished={
					onMembershipConfirmationAnimationFinished
				}
				isInLoadingState={
					membershipActivationState ===
					MembershipActivationState.SIGNING_IN_PROGRESS
				}
			/>
		);
	}

	const renderPostActivationNudge = () => {
		const postActivationNudgeContent = (
			<PostActivationNudge
				membershipDetails={membershipDetailsFromReduxStore}
			/>
		);

		return isDesktop ? (
			<MembershipActivationNudgeWrapper isMarginRequired={isCityPage}>
				{postActivationNudgeContent}
			</MembershipActivationNudgeWrapper>
		) : (
			postActivationNudgeContent
		);
	};

	return (
		<>
			<Conditional if={showMembershipNudges.preActivation}>
				<>
					<MembershipActivationNudgeWrapper
						isMarginRequired={isCityPage}
					>
						<PreMembershipActivationTopNudge
							onActivateMembershipClick={
								openActivateMembershipSheet
							}
						/>
					</MembershipActivationNudgeWrapper>
					<PreMembershipActivationSwipeSheet
						closeSheet={closeActivateMembershipSheet}
						openSheet={openActivateMembershipSheet}
						sheetRef={preActivationMembershipSheetRef}
						membershipDetails={
							resolvedMembershipPayload?.membershipDetails || null
						}
						onJoiningMembership={onJoiningMembership}
					/>
				</>
			</Conditional>
			<Conditional
				if={
					showMembershipNudges.postActivation &&
					!showMembershipNudges.preActivation
				}
			>
				{renderPostActivationNudge()}
			</Conditional>
		</>
	);
};
