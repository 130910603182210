import { clearMembershipDataFromAsyncStorage } from '@headout/aqua';

import { MembershipActivationState } from 'Containers/common/MembershipFlowHandler';

import { notify } from 'Components/common/notify';

import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import { getApiBaseUrl, removePageQuery } from 'Utils/urlUtils';

import { decrementAPICount } from 'Actions/apiCount';
import { setMembershipData } from 'Actions/membership';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';

import { QUERY_PARAM } from 'Constants/constants';

export const joinMembership =
	(
		membershipId: number,
		setMembershipActivationState: (
			state: MembershipActivationState,
		) => void,
	) =>
	(dispatch: any) => {
		const url = `${getApiBaseUrl()}/api/v1/membership/join`;
		const options = {
			method: 'POST',
			body: JSON.stringify({
				membershipId: membershipId,
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		};
		return fetch(url, options)
			.then(response => response.json())
			.then(membershipData => {
				if (membershipData?.membershipId) {
					setMembershipActivationState(
						MembershipActivationState.JOIN_IN_PROGRESS,
					);
					dispatch(
						setMembershipData({
							membershipData: membershipData ?? null,
						}),
					);
					removePageQuery(QUERY_PARAM.MEMBERSHIP_DATA);
					clearMembershipDataFromAsyncStorage();
				} else {
					throw new Error('Join Failed!');
				}
			})
			.catch(err => {
				setMembershipActivationState(
					MembershipActivationState.NOT_INITIATED,
				);
				notify.showMembershipLoginError(err, 'join');
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				dispatch(decrementAPICount());
			});
	};
